import { create } from "zustand";

const useVersionStore = create((set) => ({
  mobileVersion: "3.8.5",
  setMobileVersion: (mobileVersion) => set({ mobileVersion }),

  serverVersion: "",
  setServerVersion: (serverVersion) => set({ serverVersion }),
}));

export default useVersionStore;
